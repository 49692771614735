<template>
  <div class="template-1">
    <page-header class="page-header container">
      <h1>{{ pageTitle }}</h1>
    </page-header>
    <page-body class="mx-lg">
      <section class="section-1">
        <div class="bg-block p-4">
          <header class="mb-2">
            <div v-if="!!this.pageError && !!this.pageError.message" class="appeal">
              {{ translations.tcErrorPleaseDescribeAction }}
            </div>
          </header>
          <div class="d-flex row details">
            <b-container>
              <b-row class="detail">
                <b-col sm="2" class="text-uppercase">
                  <div v-if="!!this.pageError && !!this.pageError.message">
                    <strong>{{ translations.tcFromPage }}:</strong>
                  </div>
                </b-col>
                <b-col sm="3">
                  <span v-if="!!this.pageError && !!this.pageError.message">{{ from }}</span>
                </b-col>
                <b-col sm="2" class="text-uppercase">
                  <strong>{{ translations.tcSubject }}:</strong>
                </b-col>
                <b-col sm="4">
                  <b-form-input :placeholder="translations.tcBriefDescription" v-model="subject" trim></b-form-input>
                </b-col>
              </b-row>

              <b-row class="detail">
                <b-col sm="2" class="text-uppercase">
                  <strong>{{ translations.tcMember }}:</strong>
                </b-col>
                <b-col sm="3">
                  <span>{{ demograph.formal_name }}</span>
                </b-col>
                <b-col sm="2" class="text-uppercase">
                  <strong>{{ translations.tcPhone }}:</strong>
                </b-col>
                <b-col sm="3">
                  <span>
                    <b-form-input v-model="phone" trim></b-form-input>
                  </span>
                </b-col>
              </b-row>

              <b-row class="detail">
                <b-col sm="2" class="text-uppercase">
                  <strong>{{ translations.tcMemberNumber }}:</strong>
                </b-col>
                <b-col sm="3">
                  <span>{{ demograph.member_number }}</span>
                </b-col>
                <b-col sm="2" class="text-uppercase">
                  <strong>{{ translations.tcEmail }}:</strong>
                </b-col>
                <b-col sm="3">
                  <span>
                    <b-form-input v-model="email" trim></b-form-input>
                  </span>
                </b-col>
              </b-row>
              <b-row class="detail text-uppercase">
                <b-col sm="2">
                  <strong>{{ translations.tcCamp }}:</strong>
                </b-col>
                <b-col sm="3">
                  <span>{{ demograph.camp_name }} ({{ demograph.camp_number }})</span>
                </b-col>
              </b-row>
              <b-row class="detail">
                <b-col sm="2" class="text-uppercase">
                  <strong style="line-height: 20px">{{ translations.tcResponseRequested }}:</strong>
                </b-col>
                <b-col sm="3">
                  <span>
                    <b-form-checkbox v-model="response" name="check-button" switch>
                      {{ response ? translations.tcYes : translations.tcNo }}
                    </b-form-checkbox>
                  </span>
                </b-col>
                <b-col sm="5">
                  <strong class="text-uppercase">{{ translations.tcFeedback }}:</strong>
                  <span>
                    <b-form-textarea
                      v-model="comment"
                      :placeholder="translations.tcDescribeIssueInDetail"
                      rows="6"
                      max-rows="8"
                      trim
                    ></b-form-textarea>
                  </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="5">
                  <div class="detail buttons">
                    <span>
                      <b-button
                        @click="handleSubmitClick()"
                        variant="secondary"
                        class="btn flex-0 mr-3 w-100-sd mb-3 mb-sm-0"
                        >{{ translations.tcSubmit }}</b-button
                      >
                      <b-button
                        @click="handleCancelClick()"
                        variant="primary"
                        class="btn flex-0 mr-3 w-100-sd mb-3 mb-sm-0"
                        >{{ translations.tcCancel }}</b-button
                      >
                    </span>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
      </section>
    </page-body>
    <section style="width: 90%; height: 200px">
      <b-form>
        <b-row>
          <b-col sm="6">&nbsp;</b-col>
          <b-col sm="2" class="font-style-3">
            <h6 style="font-weight: 700; line-height: 26px">
              THE GIDEONS INTERNATIONAL<br />
              PO Box 140800<br />
              Nashville, TN 37214-0800
              <br /><br />
              Phone: (615) 564-5000<br />
              Fax: (615) 564-6000
            </h6>
          </b-col>
        </b-row>
      </b-form>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'contact-us',
  mixins: [translationMixin],
  data() {
    return {
      email: '',
      from: 'None',
      phone: '',
      subject: '',
      comment: '',
      response: false,
      message: '',
      page_error: '',
      translations: {},
    }
  },
  components: {
    pageHeader: pageHeader,
    pageBody: pageBody,
    pageFooter: pageFooter,
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      fromPage: 'user/fromPage',
      pageError: 'user/pageError',
      prefCulture: 'user/userPreferredCulture',
    }),
    pageTitle() {
      return !!this.pageError && !!this.pageError.message ? this.translations.tcFeedback : this.translations.tcContactUs
    },
    validForm() {
      return (
        this.email.length > 9 &&
        this.email.includes('@') &&
        this.phone.length > 9 &&
        this.subject.length > 3 &&
        this.comment.length > 0
      )
    },
  },
  methods: {
    ...mapActions({
      sendFeedback: 'user/sendFeedback',
      setFromPage: 'user/setFromPage',
      setLoadingStatus: 'menu/setLoadingStatus',
      setPageError: 'user/setPageError',
    }),
    async buildBody() {
      return {
        page: this.from,
        page_error: this.page_error,
        comment: this.comment,
        subject: this.subject,
        response: this.response,
        member_number: this.demograph.member_number,
        member_name: this.demograph.formal_name,
        camp_name: this.demograph.camp_name,
        camp_number: this.demograph.camp_number,
        phone: this.phone,
        email: this.email,
        timestamp: new Date(),
        browser_name: this.$browserDetect.meta.name,
        browser_version: this.$browserDetect.meta.version,
        browser_user_agent: this.$browserDetect.meta.ua,
      }
    },
    async handleCancelClick() {
      this.setFromPage('')
      this.setPageError('')
      this.$router.push('/')
    },
    async handleSubmitClick() {
      this.message = ''
      let fb = null
      if (this.validForm) {
        await Promise.all([
          this.setLoadingStatus(true),
          (fb = await this.buildBody()),
          await this.sendFeedback({ key: this.demograph.ind_key, msg: fb }),
        ]).then(() => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: 'success',
            text: this.translations.tcYourMessageHasBeenSubmitted,
            confirmButtonText: this.translations.tcOk,
          }).then(() => {
            this.handleCancelClick()
          })
        })
      } else {
        await this.$swal({
          icon: 'error',
          text: this.translations.tcCompleteAllFields,
          confirmButtonText: this.translations.tcOk,
        })
      }
    },
  },
  async created() {
    await Promise.all([
      this.setLoadingStatus(true),
      await this.getViewTranslations(),
      (this.phone = this.demograph.phone),
      (this.email = this.demograph.email),
    ]).then(() => {
      if (!!this.pageError && !!this.pageError.message) {
        this.page_error = `${this.pageError.name} ${this.pageError.message} ${this.pageError.stack}`
        this.subject = 'An error occurred while I was working on the site.'
        this.from = this.fromPage
      }
      this.setLoadingStatus(false)
    })
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
.detail {
  padding: 10px 0;
}
.page {
  margin-top: 0;
}
.page-body {
  padding: 0;
}
.appeal {
  margin-bottom: 0;
  color: #cc0000;
  font-weight: 600;
  margin-left: 40px;
  font-style: italic;
}
.buttons {
  padding-top: 20px;
}
.filler {
  height: 13px;
}
.message {
  padding-top: 30px;
  color: #cc0000;
}
.form-control {
  border-color: #747474;
  color: #241e1e;
}
.custom-switch {
  margin-top: 10px;
}
.section-1 {
  margin-bottom: 30px;
}

strong.lh50 {
  line-height: 50px !important;
}

.details {
  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
  .col_a .detail {
    strong {
      width: 218px;
    }
  }
  .col {
    width: 33.3333%;
    @include breakpoint(sm) {
      flex: 0 0 100%;
      width: 100%;
    }
  }
  .detail {
    display: flex;
    align-items: flex-start;
    font-size: 15px;
    strong,
    span {
      display: block;
      color: #241e1e;
      letter-spacing: 1px;
      line-height: 38px;
    }
    strong {
      flex: 0 0 auto;
      width: 150px;
      padding-right: 10px;
      text-transform: uppercase;
      font-weight: 900;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    span {
      flex: 1 1 auto;
      align-self: flex-end;
    }
    &.full-detail {
      display: block;
    }
  }
}
</style>
